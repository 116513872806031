<template>
  <div class="o-sticky">
    <div class="sticky-plane">
      <div :class="containerClass">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'o-sticky',
  props: {
    aligned: {
      type: String,
      default: 'right',
    },
    containerClass: {
      type: String,
      default: 'container-fluid',
    },
  },
  mounted() {
    this.$store.commit('page/setClasses', 'has-sticky');
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

.o-sticky {
  display: block;
}
.sticky-plane {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: right;
  z-index: 10000;
}
.padded {
  padding: $space-default;
}
</style>
